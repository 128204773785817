import { Link } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import { ActionCards, ActionCard } from "@truenorthmortgage/olympus";
import { useIntl } from "react-intl";
import ContactUs from "../modal/ContactUs";
import { UserContext } from "../App";
import { loansQuery } from "../queries";
import { useQuery } from "@apollo/client";
import {
  capitalize,
  formatAddressLine,
  handleQueryError,
  nMonthsToYearMonthString,
} from "../utils";
import ModalComponent from "../components/ModalComponent";
import TagDiv from "../components/TagDiv";
import { LoansQuery } from "../models/loan";

const Dashboard = () => {
  const intl = useIntl();
  const TIME_OF_DAY = [
    intl.formatMessage({ id: "Morning" }),
    intl.formatMessage({ id: "Afternoon" }),
    intl.formatMessage({ id: "Evening" }),
  ];

  const getGreetingBasedOnTime = () => {
    const now = new Date();
    if (now.getHours() >= 4 && now.getHours() < 12) {
      return TIME_OF_DAY[0];
    } else if (now.getHours() >= 12 && now.getHours() < 18) {
      return TIME_OF_DAY[1];
    }
    if (now.getHours() >= 18 || now.getHours() < 4) {
      return TIME_OF_DAY[2];
    }
    return TIME_OF_DAY[0];
  };

  const { user } = useContext(UserContext);
  const greeting = intl.formatMessage(
    { id: "Good {timeOfDay} {name}" },
    { name: user.firstName, timeOfDay: getGreetingBasedOnTime() }
  );
  const optionLabel = intl.formatMessage({ id: "Options" });
  const helocLabel = intl.formatMessage({ id: "Home Equity Line Of Credit" });
  const conciergeLabel = intl.formatMessage({ id: "Concierge" });
  const amortizingLabel = intl.formatMessage({ id: "Mortgages" });
  const contactLabel = intl.formatMessage({ id: "Contact THINK Financial" });
  const accountLabel = intl.formatMessage({ id: "My THINK Account" });
  const [hasHeloc, setHasHeloc] = useState<boolean>(false);
  const [hasConcierge, setHasConcierge] = useState<boolean>(false);
  const [hasAmortizing, setHasAmortizing] = useState<boolean>(false);
  const LOAN_TYPES = {
    heloc: "heloc",
    concierge: "concierge",
    amortizing: "amortizing",
  };

  const setLoanAvailabilityByCategory = (data: any) => {
    if (!data) {
      return;
    }
    for (let i = 0; i < data.borrowerLoans.length; i++) {
      if (data.borrowerLoans[i].product.category === LOAN_TYPES["heloc"]) {
        setHasHeloc(true);
      } else if (
        data.borrowerLoans[i].product.category === LOAN_TYPES["concierge"]
      ) {
        setHasConcierge(true);
      } else if (
        data.borrowerLoans[i].product.category === LOAN_TYPES["amortizing"]
      ) {
        setHasAmortizing(true);
      }
    }
  };

  const { data: loanData, loading: loadingLoans } = useQuery<LoansQuery>(
    loansQuery,
    {
      variables: {
        productCategories: ["HELOC", "CONCIERGE", "AMORTIZING"],
      },
      onCompleted(data: any) {
        setLoanAvailabilityByCategory(data);
      },
      onError(loanError: any) {
        handleQueryError(loanError, "Failed to load loans");
      },
    }
  );

  const [modalOpen, setModalOpen] = useState(false);

  const cards = useMemo(
    () => [
      {
        label: accountLabel,
        icon: "head-side",
        path: "/account",
      },
    ],
    [accountLabel]
  );

  return loadingLoans ? (
    <div className="loading-full">
      <img src="/think-loader.svg" alt="loader" />
    </div>
  ) : (
    <div className="dashboard">
      <h1>{greeting}</h1>
      <h2>{optionLabel}</h2>
      <div className="divider" />
      <ActionCards>
        {cards.map(({ label, icon, path }) => (
          <ActionCard key={icon} path={path}>
            <i className={"fa fa-" + icon}></i>
            <br />
            <span>{label}</span>
          </ActionCard>
        ))}

        <ActionCard onClick={() => setModalOpen(true)}>
          <i className={"fa fa-phone-rotary"}></i>
          <br />
          <span>{contactLabel}</span>
        </ActionCard>
      </ActionCards>

      <ModalComponent
        modalHeader={intl.formatMessage({ id: "Contact Us" })}
        isOpen={modalOpen}
        closeFunc={() => setModalOpen(false)}
        iconClass="fa fa-close"
      >
        <ContactUs />
      </ModalComponent>
      {hasAmortizing && (
        <>
          <h2>{amortizingLabel}</h2>
          <div className="divider" />
          {loanData?.borrowerLoans.map((loan) => {
            if (loan.product.category === LOAN_TYPES["amortizing"]) {
              return (
                <Link
                  className="loan-row"
                  key={loan.reference}
                  to={"/mortgage/" + loan.reference + "/details"}
                >
                  <div>
                    <div className="status-details">
                      {loan.status.name !== "Active" && (
                        <TagDiv
                          id="status"
                          text={loan.status.name}
                          color={loan.status.color}
                        />
                      )}
                      <p className="loan-row-details">
                        {nMonthsToYearMonthString(
                          loan.product.termLengthInMonths
                        )}
                        &nbsp;
                        {capitalize(loan.product.type || "")}
                        &nbsp;
                        <span className="primary" data-dd-privacy="mask">
                          {loan.reference}
                        </span>
                      </p>
                    </div>
                    <h3 data-dd-privacy="mask">
                      <span className="primary">
                        {/* Current rate is prime + variance for variable loans, or interest for fixed */}
                        {loan.loanRate.currentRate}%
                      </span>
                      &nbsp;&nbsp;
                      {formatAddressLine(loan.address)}
                    </h3>
                  </div>
                  <i className="fa fa-chevron-right"></i>
                </Link>
              );
            }
          })}
        </>
      )}
      {hasHeloc && (
        <>
          <h2>{helocLabel}</h2>
          <div className="divider" />
          {loanData?.borrowerLoans.map((heloc) => {
            if (heloc.product.category === LOAN_TYPES["heloc"]) {
              return (
                <Link
                  className="loan-row"
                  key={heloc.reference}
                  to={"/heloc/" + heloc.reference + "/details"}
                >
                  <div>
                    <p>
                      HELOC&nbsp;
                      <span className="primary" data-dd-privacy="mask">
                        {heloc.reference}
                      </span>
                    </p>
                    <h3 data-dd-privacy="mask">
                      <span className="primary">
                        {heloc.loanRate.currentRate}%
                      </span>
                      &nbsp;&nbsp;
                      {formatAddressLine(heloc.address)}
                    </h3>
                  </div>
                  <i className="fa fa-chevron-right"></i>
                </Link>
              );
            }
          })}
        </>
      )}
      {hasConcierge && (
        <>
          <h2>{conciergeLabel}</h2>
          <div className="divider" />
          {loanData?.borrowerLoans.map((concierge) => {
            if (concierge.product.category === LOAN_TYPES["concierge"]) {
              return (
                <Link
                  className="loan-row"
                  key={concierge.reference}
                  to={"/concierge/" + concierge.reference + "/details"}
                >
                  <div>
                    <p>
                      Concierge&nbsp;
                      <span className="primary" data-dd-privacy="mask">
                        {concierge.reference}
                      </span>
                    </p>
                    <h3 data-dd-privacy="mask">
                      <span className="primary">
                        {/* // TODO this rate may be wrong, need to confirm */}
                        {concierge.loanRate.currentRate}%
                      </span>
                      &nbsp;&nbsp;
                      {formatAddressLine(concierge.address)}
                    </h3>
                  </div>
                  <i className="fa fa-chevron-right"></i>
                </Link>
              );
            }
          })}
        </>
      )}
      <div className="footer-content"></div>
    </div>
  );
};

export default Dashboard;
