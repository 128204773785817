import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
} from "@apollo/client";
import { pushNotification, store, utils } from "@truenorthmortgage/olympus";
import { useCallback } from "react";
import { Address } from "./models/address";

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const parseError = (error: any) => {
  if (error) {
    let errorString = "Please check inputs";
    for (const match of error.message.matchAll(
      /Variable "(?<field>.*)": (?<message>.*)\./g
    )) {
      errorString += `\n\n${match.groups.field}: ${match.groups.message}`;
    }

    return errorString;
  }
};

export const handleQueryError = (
  error: any,
  message: string,
  setErrorFunc?: any
) => {
  if (window.location.pathname !== "/login" && error.message.match("401")) {
    sessionStorage.removeItem("thinkToken");
    notify("Please log back in", "error");
    window.location.replace("/login");
  } else if (error.message.match("503")) {
    notify(
      "The server is currently unavailable. Please try again later.",
      "error"
    );
  } else {
    if (setErrorFunc) {
      setErrorFunc(message);
    } else {
      notify(message, "error");
    }
  }
};

export const notify = (message: string, className = "success") => {
  store.dispatch(
    pushNotification({
      message,
      class: className,
    })
  );
};

export const valuesToInt = (params: any, keys: string[]) => {
  const newParams = { ...params };

  Object.keys(newParams).forEach((key) => {
    if (keys.includes(key)) {
      const val = newParams[key]
        .replace("$", "")
        .replace(/,/g, "")
        .replace(".", "");

      if (!val || isNaN(val)) return;
      newParams[key] = parseInt(val);
    }
  });
  return newParams;
};

export const valuesToDate = (params: any, keys: string[]) => {
  const newParams = { ...params };
  Object.keys(newParams).forEach((key) => {
    if (keys.includes(key)) {
      if (!newParams[key]) return;
      newParams[key] = utils.date.dbDate(newParams[key]);
    }
  });
  return newParams;
};

export function dateStringToUTCDate(dateString: string) {
  const isoString = new Date(dateString).toISOString();
  return new Date(isoString.slice(0, -1));
}

export const formatDateYYYYMMDD = (date: Date) => {
  return date.toISOString().split("T")[0].replace(/[-]+/g, "");
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export function useQueryWithRefresh<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): [
  (variables?: TVariables) => Promise<TData | undefined>,
  QueryResult<TData, TVariables>
] {
  const [get, result] = useLazyQuery<TData, TVariables>(query, options);

  const getPromiseValue = useCallback(
    (variables?: TVariables) => {
      return (
        result.called ? result.refetch(variables) : get({ variables })
      ).then((d) => d.data);
    },
    [get, result]
  );

  return [getPromiseValue, result];
}

export const nMonthsToYearMonthString = (nMonths: number) => {
  const years = Math.floor(nMonths / 12);
  const months = nMonths % 12;

  return `${years ? `${years} Year${years > 1 ? "s" : ""}` : ""}${
    months && years ? ", " : ""
  }${months ? `${months} Month${months > 1 ? "s" : ""}` : ""}`;
};

/**
 * Split a string on space and underscore and capitalizes each word before concatenating
 * @param paymentFrequency string of upper case payment frequency atom
 * @returns Payment frequency in human readable format
 */
export function paymentFrequencyToHumanReadable(
  paymentFrequency: string
): string {
  // Split and capitalize each word
  const words = paymentFrequency.split(/[ _]/);
  words.forEach(function (word, index, thisArray) {
    thisArray[index] = capitalize(word);
  });

  switch (paymentFrequency) {
    case "BIWEEKLY_ACCELERATED":
      return "Bi-Weekly Accelerated";
    case "BIWEEKLY":
      return "Bi-Weekly";

    default:
      return words.join(" ");
  }
}

export const toLocalFromUTC = (dateStr: string): Date => {
  const date = new Date(dateStr);
  const offsetInMinutes: number = getCurrentTimezoneOffset();
  return new Date(date.setMinutes(date.getMinutes() - offsetInMinutes));
};

export const getCurrentTimezoneOffset = (): number => {
  return new Date().getTimezoneOffset();
};

export const formatAddressLine = (address?: Address) => {
  if (!address) {
    return "";
  }

  return `${
    address?.lineOne && address?.lineTwo ? address.lineTwo + " - " : ""
  }${address?.lineOne ? address.lineOne : ""}`;
};

export const getLumpSumSourceOfFundsLimit = () => {
  return 1000000;
};

export const isSourceOfFundsRequired = (amount: number): boolean => {
  // amount in cents over $10,000 requires source of funds
  return amount >= getLumpSumSourceOfFundsLimit();
};

export const isSourceOfFundsOther = (sourceOfFunds: string): boolean => {
  return sourceOfFunds === "OTHER";
};

export const getSourceOfFunds = (
  sourceOfFunds: string,
  sourceOfFundsOther: string
): string => {
  if (sourceOfFunds === "OTHER") {
    return sourceOfFundsOther;
  }
  return sourceOfFunds;
};
