import { useMutation, useQuery } from "@apollo/client";
import {
  TextComponent,
  DateComponent,
  Form,
  MoneyComponent,
  SelectComponent,
  Widget,
  utils,
} from "@truenorthmortgage/olympus";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { changeRequestMutation } from "../queries";
import {
  handleQueryError,
  notify,
  valuesToDate,
  isSourceOfFundsRequired,
  isSourceOfFundsOther,
  getLumpSumSourceOfFundsLimit,
  getSourceOfFunds,
} from "../utils";
import dayjs from "dayjs";
import { ChangeRequestMutation } from "../models/payment";
import { SourceOfFundsEnumQuery } from "../models/enum";
import { sourceOfFundsEnumQuery } from "../queries";

export type RecurringPaymentProps = {
  data?: {
    accounts: { value: string; label: string }[];
    heloc: any;
  };
  closeFunc: any;
};
const RecurringPayment: FC<RecurringPaymentProps> = ({ data, closeFunc }) => {
  const intl = useIntl();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, string | number | Date>>(
    {}
  );
  const [callbacks, setCallbacks] = useState<
    Record<string, (value: string | number | Date | null | undefined) => void>
  >({});
  const currentDate = useMemo(() => dayjs().startOf("day").toDate(), []);

  const onChange = useCallback(
    (fieldName: string) => {
      if (callbacks[fieldName]) {
        return callbacks[fieldName];
      }
      const callback = (value: string | number | Date | null | undefined) =>
        setState((state) => Object.assign({}, state, { [fieldName]: value }));
      callbacks[fieldName] = callback;
      setCallbacks(callbacks);
      return callback;
    },
    [callbacks, setCallbacks, setState]
  );

  // Method to handle the amount change, if the amount is less than $10,000, sourceOfFunds and sourceOfFundsOther are removed
  // to keep the state clean
  const onAmountChange = useCallback(
    (newAmount: number | undefined) => {
      setState((prevState) => {
        if (!newAmount) {
          return {
            ...prevState,
            amount: "",
          };
        }

        if (!!newAmount && newAmount < getLumpSumSourceOfFundsLimit()) {
          // Remove sourceOfFunds / sourceOfFundsOther
          const {
            sourceOfFunds: _sourceOfFunds, // rename so ESLint doesn't complain
            sourceOfFundsOther: _sourceOfFundsOther,
            ...rest
          } = prevState;

          return {
            ...rest,
            amount: newAmount,
          };
        } else {
          // Keep everything else, just update amount
          return {
            ...prevState,
            amount: newAmount,
          };
        }
      });
    },
    [setState]
  );

  const sourceOfFundsRequired = useMemo((): boolean => {
    return isSourceOfFundsRequired(state.amount as number);
  }, [state]);
  const sourceOfFundsOther = useMemo((): boolean => {
    return isSourceOfFundsOther(state.sourceOfFunds as string);
  }, [state]);

  let sourceOfFundsOptions: SourceOfFundsEnumQuery["__type"]["enumValues"] = [];
  const { data: sourceOfFundsData, loading: sourceOfFundsLoading } =
    useQuery<SourceOfFundsEnumQuery>(sourceOfFundsEnumQuery, {
      onError(loanError) {
        handleQueryError(
          loanError,
          "Failed to load Source of Funds type options."
        );
      },
    });

  if (!sourceOfFundsLoading) {
    sourceOfFundsOptions = sourceOfFundsData!.__type.enumValues;
  }

  const [changeRequest] = useMutation<ChangeRequestMutation>(
    changeRequestMutation,
    {
      onError(requestError) {
        handleQueryError(
          requestError,
          `Failed to submit payment request: ${requestError.message}`
        );
      },
      onCompleted(_data) {
        close();
        notify("Payment request has been submitted");
      },
    }
  );

  //onChange method for the sourceOfFunds field that clears sourceOfFundsOther when changed
  const sourceOfFundsOnChange = useCallback(
    (value: string) => {
      if (value !== "OTHER") {
        setState((prev) => ({ ...prev, sourceOfFundsOther: "" }));
      }
      onChange("sourceOfFunds")(value);
    },
    [setState, onChange]
  );

  const validateParams = useCallback((params: any) => {
    const amount = params.amount;
    const paymentFrequency = params.paymentFrequency;
    const sourceOfFunds = params.sourceOfFunds || null;
    const sourceOfFundsOther = params.sourceOfFundsOther || null;

    if (!amount || amount <= 0) {
      return "Please enter an amount greater than $0.00";
    } else if (!params.startDate) {
      return "Please choose a start date";
    } else if (!params.endDate) {
      return "Please choose a end date";
    } else if (
      !paymentFrequency ||
      paymentFrequency === "-- Select a Frequency --"
    ) {
      return "Please choose a payment frequency";
    } else if (
      amount >= getLumpSumSourceOfFundsLimit() &&
      (sourceOfFunds === null || sourceOfFunds === "")
    ) {
      return "Must provide source of funds for payments on or over $10,000";
    } else if (sourceOfFunds === "OTHER" && !sourceOfFundsOther) {
      return "Must provide a source of funds";
    }
    return "";
  }, []);

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    const effectiveDate = utils.date.dbDate(new Date());

    const parsedData = valuesToDate(state, ["startDate", "endDate"]);

    const error = validateParams(parsedData);

    if (sourceOfFundsRequired) {
      parsedData["sourceOfFunds"] = getSourceOfFunds(
        parsedData.sourceOfFunds,
        parsedData.sourceOfFundsOther
      );
    }

    // Delete the sourceOfFundsOther key from the parsedData object, safe to do so as it is not needed in the mutation
    // Prevents an error from being thrown if $10,000 amount is selected then changed to less than $10,000
    delete parsedData.sourceOfFundsOther;

    if (!error) {
      await changeRequest({
        variables: {
          loanNumber: data?.heloc.reference,
          type: "CREATERECURRINGPAYMENT",
          effectiveDate,
          data: parsedData,
        },
      });
    } else {
      notify(error, "error");
    }

    setIsSubmitted(false);
  }, [changeRequest, state, data, validateParams]);

  const close = () => {
    setState({
      amount: "",
      startDate: "",
      endDate: "",
      paymentFrequency: "",
      sourceOfFunds: "",
      sourceOfFundsOther: "",
    });
    closeFunc();
  };

  return data ? (
    <Widget className="recurring-payment">
      <Form>
        <div className="column">
          <FormattedMessage id="A request may take up to five days to process" />
        </div>
        <div className="column">
          <MoneyComponent
            id="input-amount"
            label={intl.formatMessage({ id: "Payment Amount" })}
            onChangeCents={onAmountChange}
            cents={state["amount"] as number}
          />
        </div>
        <DateComponent
          minDate={currentDate}
          label={intl.formatMessage({ id: "Start Date" })}
          onChange={onChange("startDate")}
          value={state["startDate"] as Date}
        />
        <DateComponent
          minDate={state["startDate"] as Date}
          label={intl.formatMessage({ id: "End Date" })}
          onChange={onChange("endDate")}
          value={state["endDate"] as Date}
        />
        <SelectComponent
          onChange={onChange("paymentFrequency")}
          value={state["paymentFrequency"] as string}
          label={intl.formatMessage({ id: "Frequency" })}
          defaultValue={"-- Select a Frequency --"}
        >
          {[
            "-- Select a Frequency --",
            "MONTHLY",
            "SEMI_MONTHLY",
            "WEEKLY",
            "BIWEEKLY",
          ].map((frequency) => (
            <option key={frequency} value={frequency}>
              {intl.formatMessage({ id: frequency })}
            </option>
          ))}
        </SelectComponent>
        <div className="column">
          {sourceOfFundsRequired ? (
            <SelectComponent
              id="source-of-funds-selction"
              empty={"-- Select Source --"}
              label={intl.formatMessage({ id: "Source of Funds" })}
              value={state["sourceOfFunds"] as string}
              onChange={sourceOfFundsOnChange}
            >
              {sourceOfFundsOptions?.map((option) => (
                <option key={option.name} value={option.name}>
                  {option.description}
                </option>
              ))}
            </SelectComponent>
          ) : null}

          {sourceOfFundsRequired && sourceOfFundsOther ? (
            <TextComponent
              label="Source of Funds Other"
              formData={state["sourceOfFundsOther"] as string}
              onChange={onChange("sourceOfFundsOther")}
              name="sourceOfFundsOther"
            />
          ) : null}
        </div>

        <div className="column buttons">
          <div className="buttons">
            <button className="button cancel" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </button>
            <button
              className="button primary right form-trigger"
              disabled={isSubmitted}
              onClick={onSubmit}
            >
              <FormattedMessage id="Submit" />
            </button>
          </div>
        </div>
      </Form>
    </Widget>
  ) : null;
};

export default RecurringPayment;
