import {
  ActionCards,
  ActionCard,
  BasicRow,
  Table,
  TextCell,
} from "@truenorthmortgage/olympus";
import { useIntl } from "react-intl";
import ChangePassword from "../modal/ChangePassword";
import ContactUs from "../modal/ContactUs";
import UpdateAccountDetails from "../modal/UpdateAccountDetails";
import { Fragment, useContext, useState } from "react";
import { UserContext } from "../App";
import ModalComponent from "../components/ModalComponent";
import { formatAddressLine } from "../utils";

const Account = () => {
  const intl = useIntl();
  const optionLabel = intl.formatMessage({ id: "My Account Options" });
  const detailsLabel = intl.formatMessage({ id: "User Details" });
  const documentLabel = intl.formatMessage({ id: "Document Delivery" });
  const mailingAddressLabel = intl.formatMessage({ id: "Mailing Address" });
  // const mailPrefLabel = intl.formatMessage({ id: "Mail Documents" });
  // const communicationPrefLabel = intl.formatMessage({ id: "Electronic Communications" });
  const contactLabel = intl.formatMessage({ id: "Contact THINK Financial" });
  const passwordLabel = intl.formatMessage({ id: "Change My Password" });
  const updateLabel = intl.formatMessage({ id: "Update Account Details" });
  const { user } = useContext(UserContext);
  const modalDefault: any = { contact: false, password: false, account: false };

  const [modalOpen, setModalOpen] = useState(modalDefault);

  const cards = [
    {
      hide: false,
      label: contactLabel,
      icon: "phone-rotary",
      key: "contact",
      modal: {
        header: intl.formatMessage({ id: "Contact Us" }),
        elem: <ContactUs />,
      },
    },
    {
      hide: false,
      label: passwordLabel,
      icon: "key",
      key: "password",
      modal: {
        header: intl.formatMessage({ id: "Change Password" }),
        elem: <ChangePassword closeFunc={() => setModal("password", false)} />,
      },
    },
    {
      hide: true,
      label: updateLabel,
      icon: "pen",
      key: "account",
      modal: {
        header: intl.formatMessage({ id: "Edit My Contact Details" }),
        elem: (
          <UpdateAccountDetails
            data={{ currentEmail: user.email }}
            closeFunc={() => setModal("account", false)}
          />
        ),
      },
    },
  ];

  const setModal = (key: any, value: any) => {
    setModalOpen((state: any) => Object.assign({}, state, { [key]: value }));
  };

  if (!user || JSON.stringify(user) === "{}") {
    return (
      <div className="loading">
        <img src="/think-loader.svg" alt="" />
      </div>
    );
  }

  return (
    <div className="account">
      <h2>{optionLabel}</h2>
      <div className="divider" />

      <ActionCards>
        {cards.map(({ hide, label, icon, key }) => (
          <Fragment key={key}>
            {/* Hide currently unused card buttons */}
            {!hide && (
              <ActionCard key={key} onClick={() => setModal(key, true)}>
                <i className={"fa fa-" + icon}></i>
                <span>{label}</span>
              </ActionCard>
            )}
          </Fragment>
        ))}
      </ActionCards>

      {cards.map(({ hide, modal, key }) => (
        <Fragment key={key}>
          {/* Hide currently unused card modals */}
          {!hide && (
            <ModalComponent
              key={key}
              modalHeader={modal.header}
              isOpen={modalOpen[key]}
              closeFunc={() => setModal(key, false)}
              iconClass="fa fa-close"
            >
              {modal.elem}
            </ModalComponent>
          )}
        </Fragment>
      ))}

      <h2>{detailsLabel}</h2>
      <div className="divider" />
      <Table className="primary">
        <BasicRow>
          <TextCell>Name</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">
              {`${user.firstName} ${user.lastName}`}
            </div>
          </TextCell>
        </BasicRow>
        <BasicRow>
          <TextCell>Email Address</TextCell>
          <TextCell>
            <div data-dd-privacy="mask">{user.email}</div>
          </TextCell>
        </BasicRow>
      </Table>

      <h2>{documentLabel}</h2>
      <div className="divider" />

      <h4>{mailingAddressLabel}</h4>
      <p data-dd-privacy="mask">
        <div>{formatAddressLine(user.address)}</div>
        <div>
          {user.address?.city}, {user.address?.province}
        </div>
        <div>{user.address?.country}</div>
        <div>{user.address?.postalCode}</div>
      </p>
      <div className="divider-grey" />

      {/* <h4>{mailPrefLabel}</h4>
      <div className="divider-grey" />

      <h4>{communicationPrefLabel}</h4>
      <div className="divider-grey" /> */}
      <div className="footer-content"></div>
    </div>
  );
};

export default Account;
