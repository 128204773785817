import {
  utils,
  Table,
  BasicRow,
  TextCell,
  Widget,
} from "@truenorthmortgage/olympus";
import { FormattedMessage, useIntl } from "react-intl";
import ModalComponent from "../ModalComponent";
import { useMemo } from "react";
import {
  AllTransactionsQuery,
  PendingPaymentRequestsQuery,
} from "../../models/payment";

export interface RecurrentPaymentsTableComponentProps {
  label: string;
  data?: {
    modalOpen: Record<string, boolean>;
    setModal: (key: string, value: boolean) => void;
    transactionData?: AllTransactionsQuery;
    requestData: PendingPaymentRequestsQuery;
  };
  cancelRecurringPayment?: (id: string, amount: number) => void;
}

const RecurrentPaymentsTableComponent = ({
  label,
  data,
  cancelRecurringPayment,
}: RecurrentPaymentsTableComponentProps) => {
  const intl = useIntl();

  const recurringPayments = useMemo(
    () => data?.transactionData?.allBorrowerLoanTransactions.recurringPayments,
    [data?.transactionData]
  );

  return (
    <>
      <h2>{intl.formatMessage({ id: label })}</h2>
      <div className="divider" />
      <Table className="transactions x-scrollable">
        {recurringPayments && recurringPayments.length > 0 ? (
          recurringPayments.map((payment: any) => (
            <>
              <BasicRow key={"payment-" + payment.id}>
                <TextCell>
                  <div data-dd-privacy="mask">
                    {intl.formatMessage({
                      // Payment type comes in as lowercase.
                      // Translation keys are uppercase.
                      id: payment.paymentFrequency.toUpperCase(),
                    })}{" "}
                    Payment ({payment.paymentsRemaining} payments remaining)
                    <span>
                      {payment.frequencyString}
                      {", "}
                      {new Date(payment.startDate) > new Date()
                        ? "starting " +
                          utils.date.getShortDate(payment.startDate)
                        : "ending " + utils.date.getShortDate(payment.endDate)}
                    </span>
                  </div>
                </TextCell>
                <TextCell>
                  <div data-dd-privacy="mask">
                    {utils.currency.centsToDollars(payment.paymentInCents)}
                  </div>
                </TextCell>
                {cancelRecurringPayment ? (
                  <TextCell>
                    {data?.requestData?.pendingChangeRequests.find(
                      (request: any) =>
                        request.type == "CANCELRECURRINGPAYMENT" &&
                        request.data.recurringPaymentId == payment.id
                    ) ? (
                      <i className="fa fa-timer" title="Request Pending"></i>
                    ) : (
                      <button
                        className="link-button"
                        onClick={() =>
                          data?.setModal("confirmation-" + payment.id, true)
                        }
                      >
                        <i className="fa fa-trash error"></i>
                      </button>
                    )}
                  </TextCell>
                ) : (
                  <TextCell />
                )}
              </BasicRow>
              <ModalComponent
                key={"confirmation-" + payment.id}
                modalHeader={<FormattedMessage id="Cancel Recurring Payment" />}
                isOpen={
                  // do not open if the callback is not set
                  cancelRecurringPayment &&
                  data?.modalOpen["confirmation-" + payment.id]
                }
                closeFunc={() =>
                  data?.setModal("confirmation-" + payment.id, false)
                }
                iconClass="fa fa-close"
              >
                <Widget>
                  <div className="column">
                    <FormattedMessage id="A request may take up to five days to process" />
                  </div>
                  <div className="column buttons">
                    <div className="buttons">
                      <button
                        className="button cancel"
                        onClick={() =>
                          data?.setModal("confirmation-" + payment.id, false)
                        }
                      >
                        <FormattedMessage id="Cancel" />
                      </button>
                      <button
                        className="button primary right form-trigger"
                        onClick={() =>
                          cancelRecurringPayment!(
                            payment.id,
                            payment.paymentInCents
                          )
                        }
                      >
                        <FormattedMessage id="Submit" />
                      </button>
                    </div>
                  </div>
                </Widget>
              </ModalComponent>
            </>
          ))
        ) : (
          <BasicRow>
            <TextCell colSpan={3}>No Recurring Payments Found</TextCell>
          </BasicRow>
        )}
      </Table>
    </>
  );
};

export default RecurrentPaymentsTableComponent;
