const en = {
  "-- Select a Frequency --": "-- Select a Frequency --",
  "A request may take up to five days to process":
    "A request may take up to five days to process",
  "A request may take up to two business days to process":
    "A request may take up to two business days to process",
  "An adjustment statement will be added to your":
    "An adjustment statement will be added to your",
  "Are you sure you want to cancel this change request?":
    "Are you sure you want to cancel this change request?",
  "Account Created": "Account Created",
  "Activate Account": "Activate Account",
  "Set Your Account Password": "Set Your Account Password",
  "Please choose a strong password to activate your account.":
    "Please choose a strong password to activate your account.",
  "Annual Statement": "Annual Statement",
  "Back to Home": "Back to Home",
  "Balance Paid": "Balance Paid",
  Balance: "Balance",
  "Cancel Change Request": "Cancel Change Request",
  "Cancel Recurring Payment": "Cancel Recurring Payment",
  "Change Date of Payments": "Change Date of Payments",
  "Change My Password": "Change My Password",
  "Change Password": "Change Password",
  "Change Payment Schedule": "Change Payment Schedule",
  "Choose Payment Date:": "Choose Payment Date:",
  "Choose Payment Source:": "Choose Payment Source:",
  "Confirm Email:": "Confirm Email:",
  "Confirm Existing Password:": "Confirm Existing Password:",
  "Confirm Existing Username:": "Confirm Existing Username:",
  "Confirm New Password:": "Confirm New Password:",
  "Confirm New Username:": "Confirm New Username:",
  "Confirm Password": "Confirm Password",
  "Contact THINK Financial": "Contact THINK Financial",
  "Contact Us": "Contact Us",
  "Create Password": "Create Password",
  "Current Email Address": "Current Email Address",
  "Current Payment Date": "Current Payment Date",
  "Deposit into account:": "Deposit into account:",
  "Document Delivery": "Document Delivery",
  "documents section": "documents section",
  "Loan Status": "Loan Status",
  Download: "Download",
  "Edit My Contact Details": "Edit My Contact Details",
  "Electronic Communications": "Electronic Communications",
  "End Date": "End Date",
  "Existing Accounts": "Existing Accounts",
  "Forgot Password?": "Forgot Password?",
  "Forgot Password": "Forgot Password",
  "Funds Available": "Funds Available",
  "Good {timeOfDay} {name}": "Good {timeOfDay} {name}",
  "HELOC Actions": "HELOC Actions",
  "HELOC Balance": "HELOC Balance",
  "HELOC Requests": "HELOC Requests",
  "Home Equity Line Of Credit": "Home Equity Line Of Credit",
  "If you are concerned about making your next payment, please phone us as soon as possible.":
    "If you are concerned about making your next payment, please phone us as soon as possible.",
  "Info Statements": "Info Statements",
  "Loan Balance": "Loan Balance",
  "Mail Documents": "Mail Documents",
  "Mailing Address": "Mailing Address",
  "Make Payment To HELOC": "Make Payment To HELOC",
  "Make Payment": "Make Payment",
  "Mortgage Balance": "Mortgage Balance",
  "Mortgage Details": "Mortgage Details",
  "Mortgage Requests": "Mortgage Requests",
  "My Account Options": "My Account Options",
  "My THINK Account": "My THINK Account",
  "New Email:": "New Email:",
  "New Password:": "New Password:",
  "New Username:": "New Username:",
  "Next Payment Amount": "Next Payment Amount",
  "Next Payment Date": "Next Payment Date",
  "Not Found": "Not Found",
  "Past Transactions": "Past Transactions",
  "Payment Amount": "Payment Amount",
  "Payout HELOC": "Payout HELOC",
  "Pending Requests": "Pending Requests",
  "Please Log In": "Please Log In",
  "Property Details": "Property Details",
  "Property Info Details": "Property Info Details",
  "Read New Messages": "Read New Messages",
  "Recurring Payments": "Recurring Payments",
  "Remaining Lump Principal Pre-payment Privileges":
    "Remaining Lump Principal Pre-payment Privileges",
  "Remember Me": "Remember Me",
  "Request Payment Date Change": "Request Payment Date Change",
  "Request Pre-Payment": "Request Pre-Payment",
  "Requested New Payment Date:": "Requested New Payment Date:",
  "Reset Password": "Reset Password",
  "Send Reset Email": "Send Reset Email",
  "Set New Password": "Set New Password",
  "Set Up Recurring Payment": "Set Up Recurring Payment",
  "Set Up Recurring Payments": "Set Up Recurring Payments",
  "Sorry, we could not find the view you are looking for.":
    "Sorry, we could not find the view you are looking for.",
  "Start Date": "Start Date",
  "Starting Balance": "Starting Balance",
  "Upcoming Transactions": "Upcoming Transactions",
  "Update Account Details": "Update Account Details",
  "Update My Contact Details": "Update My Contact Details",
  "User Details": "User Details",
  "Withdraw from HELOC": "Withdraw from HELOC",
  "Withdraw Funds From HELOC": "Withdraw Funds From HELOC",
  "Your next regular payment must complete the current payment schedule.":
    "Your next regular payment must complete the current payment schedule.",
  Actions: "Actions",
  Afternoon: "Afternoon",
  Amount: "Amount",
  BIWEEKLY: "Biweekly",
  BIWEEKLY_ACCELERATED: "Biweekly Accelerated",
  Cancel: "Cancel",
  CANCELRECURRINGPAYMENT: "Cancel Recurring Payment",
  Concierge: "Concierge",
  Continue: "Continue",
  CREATEPAYMENT: "Payment",
  CREATERECURRINGPAYMENT: "Recurring Payment",
  CREATEWITHDRAWAL: "Withdrawal",
  Email: "Email",
  Evening: "Evening",
  Frequency: "Frequency",
  HELOC: "HELOC",
  "Latest Information Statement": "Latest Information Statement",
  Login: "Login",
  Mail: "Mail",
  monthly: "Monthly",
  MONTHLY: "Monthly",
  Morning: "Morning",
  Mortgages: "Mortgages",
  On: "On",
  Options: "Options",
  Password: "Password",
  "Upcoming Transactions Tooltip":
    "Upcoming Transactions only shows regular and one time lump sum payments. Please see Recurring Payments for any additional payments that are scheduled.",

  SEMI_MONTHLY: "Semi-Monthly",
  Submit: "Submit",
  Update: "Update",
  weekly: "Weekly",
  WEEKLY: "Weekly",
  WEEKLY_ACCELERATED: "Weekly Accelerated",
  "Property Address": "Property Address",
  "Principal Balance": "Principal Balance",
  "Interest Rate": "Interest Rate",
  Product: "Product",
  Term: "Term",
  "Payment Frequency": "Payment Frequency",
  "Maturity Date": "Maturity Date",
  "Property Tax": "Property Tax",
  "Remaining Amortization": "Remaining Amortization",
  Transaction: "Transaction",
  Payment: "Payment",
  Phone: "Phone",
  "Pre-Authorized Debit Agreement (EN)": "Pre-Authorized Debit Agreement (EN)",
  "Pre-Authorized Debit Agreement (FR)": "Pre-Authorized Debit Agreement (FR)",
  "General THINK Documents": "General THINK Documents",
  "Your Concierge Documents": "Your Concierge Documents",
  "Your Mortgage Documents": "Your Mortgage Documents",
  "Welcome to THINK": "Welcome to THINK",
  "End of Day Balance": "End of Day Balance",
  "Principal + Interest Payment": "Principal + Interest Payment",
  "Principal Privilege Payment": "Principal Privilege Payment",

  // Payment Schedule Entry type translations
  principal: "Principal",
  interest: "Interest",
  fee: "Fee",
  catchup: "Catchup",

  // Journal entry type translations
  borrowerpayment: "Payment",
  borrowerwithdrawal: "Withdrawal",
  createmortgage: "Loan Setup",
  accountadjustment: "Account Adjustment",
  importmortgage: "Loan Import",
  interestpayment: "Interest",
  interestreinstatement: "Interest Reinstatement",
  nsffee: "NSF Fee",
  // need to leave the ones below in place to handle old data
  nsffeerefund: "NSF Fee Refund",
  nsfreinstatement: "NSF Reinstatement",
  payoutmortgage: "HELOC Payout",
  investorpurchase: "Investor Purchase",
  investorpayout: "Investor Payout",
  investorfunding: "Investor Funding",
  investortransferout: "Investor Transfer Out",
  investorinteresttransfer: "Investor Interest Transfer",
  nsfreversal: "NSF Reversal",
};

export default en;
